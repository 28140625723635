<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
      <a-form-model-item label="产品小类名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="产品小类类别" prop="kind">
        <a-select v-model="form.kind">
          <a-select-option v-for="filter in filterKindOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="产品小类类型" prop="type">
        <a-select v-model="form.type">
          <a-select-option v-for="filter in filterTypeOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="配网说明URL" prop="distributionNetworkUrl">
        <a-input v-model="form.distributionNetworkUrl" />
      </a-form-model-item>
      <a-form-model-item label="产品大类" prop="productClassId">
        <a-input :value="form.productClassId ? form.productClassName : '未选择'" @click="handleProductClassSelect">
          <a-tooltip slot="suffix" title="Extra information">
            <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="关联产品（物模型）" prop="productId">
        <a-input
          :value="form.productId ? `${form.productDto.name}-(id:${form.productId})` : '未选择'"
          @click="handleProductSelect"
        >
          <a-tooltip slot="suffix" title="Extra information">
            <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="发布区域" prop="identifier">
        <a-input
          :value="`已选择${form.regionIdentifiers.length}个(${form.regionIdentifiers.map((region) => region)})`"
          @click="handleRegionSelect"
        >
          <a-tooltip slot="suffix" title="Extra information">
            <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-textarea v-model="form.remark" :auto-size="{ minRows: 4, maxRows: 12 }" />
      </a-form-model-item>
      <div class="title">产品小类推荐负载类型</div>
      <a-form-model-item label=" " :colon="false" prop="recommendedLoadedDeviceTypes">
        <ProductSubClassRecommendLoadedDeviceTypeTable
          ref="productSubClassRecommendLoadedDeviceTypeTable"
          :data="form.recommendedLoadedDeviceTypes"
          :propsMetaData="metaData"
          @updateData="updateRecommendedLoadedDeviceTypes"
        />
      </a-form-model-item>
      <div class="title">产品小类图片数据</div>
      <a-form-model-item label="产品小类实体图" prop="solidImages">
        <div class="clearfix">
          <a-upload
            list-type="picture-card"
            accept="image/*"
            :customRequest="solidCustomRequest"
            :file-list="solidImages"
            @preview="handlePreview"
            :remove="removeSolidImages"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item label="产品小类线框图" prop="solidImages">
        <div class="clearfix">
          <a-upload
            list-type="picture-card"
            accept="image/*"
            :customRequest="lineCustomRequest"
            :file-list="lineImages"
            @preview="handlePreview"
            :remove="removeLineImages"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item label="产品小类渲染图" prop="solidImages">
        <div class="clearfix">
          <a-upload
            list-type="picture-card"
            accept="image/*"
            :customRequest="renderedCustomRequest"
            :file-list="renderedImages"
            @preview="handlePreview"
            :remove="removeRenderedImages"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item label="产品小类缩略图" prop="solidImages">
        <div class="clearfix">
          <a-upload
            list-type="picture-card"
            accept="image/*"
            :customRequest="thumbnailCustomRequest"
            :file-list="thumbnails"
            @preview="handlePreview"
            :remove="removeThumbnails"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-item class="group-btn">
        <a-button type="primary" @click="onSubmit">确定</a-button>
        <a-button class="creator-btn" @click="$router.back()">取消</a-button>
      </a-form-item>
    </a-form-model>
    <product-select-modal :propsMetaData="metaData" ref="productSelectModal" />
    <region-select-modal ref="regionSelectModal" />
    <product-classes-select-modal ref="productClassesSelectModal" />
    <upload-progress ref="uploadProgress" />
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import lodash from 'lodash';
import ProductClassesSelectModal from '../product-category/components/ProductClassesSelectModal';
import ProductSelectModal from '../product/components/ProductSelectModal';
import RegionSelectModal from '../region/components/RegionSelectModal';
import UploadProgress from '../../../components/upload-progress/UploadProgress';
import ProductSubClassRecommendLoadedDeviceTypeTable from './components/ProductSubClassRecommendLoadedDeviceTypeTable';

export default {
  name: 'ProductSubClassCreator',
  components: {
    RegionSelectModal,
    ProductSelectModal,
    ProductClassesSelectModal,
    UploadProgress,
    ProductSubClassRecommendLoadedDeviceTypeTable,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      solidImages: [],
      lineImages: [],
      renderedImages: [],
      thumbnails: [],
      metaData: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      propertySelect: null,
      rules: {
        name: [{ required: true, message: '产品小类名称 不能为空', trigger: 'blur' }],
        kind: [{ required: true, message: '产品小类类别 不能为空', trigger: 'blur' }],
        productClassId: [{ required: true, message: '产品大类id 不能为空', trigger: 'blur' }],
        type: [{ required: true, message: '产品小类类型 不能为空', trigger: 'blur' }],
        distributionNetworkUrl: [{ required: true, message: '配网说明URL 不能为空', trigger: 'blur' }],
        // remark: [{ required: true, message: '备注 不能为空', trigger: 'blur' }],
      },
      filterKindOptions: this.$constRepository.productSubClass.PRODUCT_SUB_CLASS_KIND_OPTIONS,
      filterTypeOptions: this.$constRepository.productSubClass.PRODUCT_SUB_CLASS_TYPE_OPTIONS,
      form: {
        name: undefined,
        kind: undefined,
        productClassId: undefined,
        type: undefined,
        distributionNetworkUrl: undefined,
        remark: undefined,
        productId: undefined,
        solidImages: [],
        lineImages: [],
        renderedImages: [],
        thumbnails: [],
        regionIdentifiers: [],
        recommendedLoadedDeviceTypes: [],
      },
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    async customRequest(request, module, submodule, imageFile) {
      this.$refs.uploadProgress.visible = true;
      const fileUploadResponse = await this.$apiManager.file.uploadFile(module, submodule, request.file);
      this.$refs.uploadProgress.visible = false;
      this.$store.commit('setUploadProgress', -1);
      if (fileUploadResponse.data.filePath) {
        const reader = new FileReader();
        reader.readAsDataURL(request.file);
        const params = {
          filePath: fileUploadResponse.data.filePath,
          uid: request.file.uid,
          status: 'done',
          name: request.file.name,
        };
        reader.onloadend = (e) => {
          const url = e.target.result;
          params.url = url;
          imageFile.push(params);
        };
      }
    },
    solidCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.productSubClass,
        this.$constRepository.file.Submodule.solidImages,
        this.solidImages,
      );
    },
    lineCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.productSubClass,
        this.$constRepository.file.Submodule.lineImages,
        this.lineImages,
      );
    },
    renderedCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.productSubClass,
        this.$constRepository.file.Submodule.renderedImages,
        this.renderedImages,
      );
    },
    thumbnailCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.productSubClass,
        this.$constRepository.file.Submodule.thumbnails,
        this.thumbnails,
      );
    },
    removeSolidImages(record) {
      this.solidImages = this.solidImages.filter((image) => image !== record);
    },
    removeLineImages(record) {
      this.lineImages = this.lineImages.filter((image) => image !== record);
    },
    removeRenderedImages(record) {
      this.renderedImages = this.renderedImages.filter((image) => image !== record);
    },
    removeThumbnails(record) {
      this.thumbnails = this.thumbnails.filter((image) => image !== record);
    },
    onSubmit() {
      this.form.lineImages = this.lineImages.map((image) => image.filePath);
      this.form.solidImages = this.solidImages.map((image) => image.filePath);
      this.form.renderedImages = this.renderedImages.map((image) => image.filePath);
      this.form.thumbnails = this.thumbnails.map((image) => image.filePath);
      // 清除没有推荐负载的明细
      this.form.recommendedLoadedDeviceTypes = this.form.recommendedLoadedDeviceTypes.filter(
        (item) => item.loadedDeviceTypeId,
      );
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.productSubClass.createProductSubClass(this.form).then(() => {
            this.$message.success('创建成功');
            this.$router.back();
          });
        }
      });
    },
    handleProductClassSelect() {
      const toDO = (select) => {
        this.form.productClassId = clonedeep(select.id);
        this.form.productClassName = clonedeep(select.name);
        this.$refs.form.validateField(['productClassId']);
      };
      this.$refs.productClassesSelectModal.showDialog(toDO);
    },
    handleProductSelect() {
      const toDO = async (select) => {
        this.$set(this.form, 'recommendedLoadedDeviceTypes', []);
        this.form.productId = clonedeep(select.productId);
        this.form.productDto = clonedeep(select);
        // 获取小类关联物模型及子物模型列表
        const response = await this.$apiManager.product.getProductById(select.productId);
        const product = response.data;
        const subProductIds = lodash.uniq(product.children || []);
        if (subProductIds.length) {
          const childrenResponse = await this.$apiManager.product.getProducts({
            productId: subProductIds.join(','),
          });
          const childrenProducts = childrenResponse.data.elements;
          const uniqChildrenProducts = lodash
            .uniqBy(childrenProducts, 'productId')
            .filter((children) => children.productId !== product.productId);
          uniqChildrenProducts.forEach((children) => {
            this.form.recommendedLoadedDeviceTypes.push({
              productId: children.productId,
              product: {
                productId: children.productId,
                productName: children.name,
                protocol: children.protocol,
                type: children.type,
              },
            });
          });
        }

        // 父节点物模型放在第一个
        this.form.recommendedLoadedDeviceTypes.unshift({
          productId: product.productId,
          product: {
            productId: product.productId,
            productName: product.name,
            protocol: product.protocol,
            type: product.type,
          },
        });
      };
      if (this.form.productId) {
        const that = this;
        this.$confirm({
          title: '是否确认修改关联的物模型',
          content: '修改后，将重置推荐负载类型列表，保存后不可撤销',
          centered: true,
          onOk() {
            that.$refs.productSelectModal.selectedRowKeys[0] = that.form.productId;
            that.$refs.productSelectModal.showDialog(toDO);
          },
        });
      } else {
        this.$refs.productSelectModal.showDialog(toDO, this.metaData.productTypes[0].value);
      }
    },
    handleRegionSelect() {
      const toDO = (select) => {
        this.form.regionIdentifiers = select;
      };
      this.$refs.regionSelectModal.showCreateDialog(toDO);
    },
    updateRecommendedLoadedDeviceTypes(value) {
      this.form.recommendedLoadedDeviceTypes = value;
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.$set(this, 'metaData', response.data);
      this.metaData = response.data;
    },
  },
  async mounted() {
    await this.getMetaData();
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
/deep/.jsoneditor-outer {
  height: 25vw;
}
.group-btn {
  margin-left: 16.7%;
}
.creator-btn{
  margin-left: 2%;
}
.title {
  margin-top: 4%;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
</style>
